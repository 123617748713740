import React from "react";
import Hero from "../modules/Hero/Hero";
import LinkBlock from "../modules/LinkBlock/LinkBlock";
import MinimalHero from "../modules/MinimalHero/MinimalHero";
import Intro from "../modules/Intro/Intro";
import Nav from "../components/Nav/Nav";

const Home = ({ data }) => {
  return (
    <>
      <Nav />
      <div className="home index-page">
        {/* Need 'headings' to also contain url? */}
        <Intro />
        {/* <LinkBlock /> */}
      </div>
    </>
  );
};

export default Home;
