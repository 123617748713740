import React, { useEffect, createContext, useContext } from "react";
import gsap from "gsap";
import { Link } from "gatsby";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import ImgLayeringLive from "../../components/ImageLayering/ImageLayering-live";
import Section from "../../components/Section/Section";
import "./MinimalHero.scss";

gsap.registerPlugin(ScrollTrigger);

const HeroContext = createContext(null);

// Jank
function wrapFirstLetter(text, both = false) {
  const firstLetter = text.charAt(0);
  const rest = text.slice(1);

  if (both) {
    return (
      <>
        <span>{firstLetter}</span>
        <span className="trail">{rest}</span>
      </>
    );
  }

  return (
    <>
      {firstLetter}
      <span>{rest}</span>
    </>
  );
}
const HeroAnimationHeading = () => {
  const [{ headings }] = useContext(HeroContext);

  return (
    <div className="minimal-hero__heading minimal-hero__heading--animation">
      <span className="logo">Seesaw</span>
      <div>
        <h1>{wrapFirstLetter(headings[0])}</h1>
        <h1>{wrapFirstLetter(headings[1])}</h1>
      </div>
    </div>
  );
};

const HeroHeadingLink = ({ heading, link = "/", position = "left" }) => {
  return (
    <>
      <h1
        className={`minimal-hero__heading minimal-hero__heading--link ${position}`}
        data-scroll
        data-scroll-sticky
        data-scroll-target=".home"
      >
        <Link to={link}>{wrapFirstLetter(heading)}</Link>
      </h1>
    </>
  );
};

const HeroHeadingLinks = () => {
  const [{ headings }] = useContext(HeroContext);
  return (
    <>
      <HeroHeadingLink heading={headings[0]} link="/" />
      <HeroHeadingLink heading={headings[1]} link="#" position="right" />
    </>
  );
};

const MinimalHero = ({
  headings = ["Studio", "Stories"],
  mouseDirection = "down",
}) => {
  function heroAnimation() {
    const heroTl = gsap.timeline({
      paused: true,
      onSart: () => {
        document.querySelector(".image_layering").style.pointerEvents = "none";
      },
      onComplete: () => {
        setTimeout(
          () => {
            document
              .querySelector(".minimal-hero__heading--animation span")
              ?.parentNode.classList.add("active");
            window.scroll.start();
            document.querySelector(".image_layering").style.pointerEvents =
              "auto";
            document.querySelector(".image_layering").style.opacity = 1;
            window.miniHeroLayering.start();
            document.body.classList.add("lock");
          },
          window.innerWidth >= 1024 ? 2500 : 1000
        );

        // setTimeout(() => {
        //   document.querySelector('.minimal-hero__heading--link.right').classList.add('z-fix');
        // }, 1200);
      },
    });

    heroTl.to(".minimal-hero__heading--link", {
      opacity: 1,
      duration: window.innerWidth >= 1024 ? 1.2 : 0,
      onComplete: () => {
        // ! Bad! If there are any future updates that require the hero components to be re-rendered due to state changes then the changes
        // ! below will reset back to default.
        document
          .querySelectorAll(".minimal-hero__heading--link")
          .forEach((el) => {
            setTimeout(() => {
              el.classList.add("active");
            }, 1200);

            setTimeout(() => {
              el.addEventListener("mouseenter", (e) => {
                e.target.querySelector("span").removeAttribute("style");
                document
                  .querySelectorAll(".minimal-hero__heading--link")
                  .forEach((el) => {
                    if (el !== e.currentTarget) {
                      el.classList.remove("hover");
                    }
                  });
                el.classList.add("hover");
              });
            }, 2000);
          });
      },
    });

    const heroToNavTl = gsap.timeline({
      scrollTrigger: {
        trigger: ".hero",
        scroller: window.scroll.el,
        start: "top top",
        end: `bottom top`,
        scrub: 0.1,
      },
    });

    // TODO: Fix the animation between 1024 and 1500px width where when it only moves on the Y axis (towards the end)
    // ? Using CSS' clamp function to make it responsive however the drawback is performance?
    heroToNavTl
      .to(".minimal-hero__heading--link.left", {
        height: "40px",
        duration: 1,
      })
      .fromTo(
        ".minimal-hero__heading--link.left",
        {
          fontSize: "clamp(40px, 12.508333333vw, 250px)",
        },
        {
          fontSize: "clamp(40px, 1vw, 250px)",
          duration: 1,
        },
        0
      )
      .to(
        ".minimal-hero__heading--link.right",
        {
          height: "40px",
          duration: 1,
        },
        0
      )
      .fromTo(
        ".minimal-hero__heading--link.right",
        {
          fontSize: "clamp(40px, 12.508333333vw, 250px)",
        },
        {
          fontSize: "clamp(40px, 1vw, 250px)",
          duration: 1,
        },
        0
      );

    heroTl.play();
  }

  useEffect(() => {
    // if(document.readyState === 'complete') {
    //   heroAnimation();
    // } else {
    //   window.addEventListener('load', heroAnimation);
    // }

    document.fonts.ready.then(function () {
      heroAnimation();
    });
  }, []);

  return (
    <HeroContext.Provider
      value={[
        {
          headings: headings,
          mouseDirection: mouseDirection,
        },
      ]}
    >
      <Section
        classes={`hero${
          mouseDirection.length === 0 ? " default-cursor" : ""
        } minimal`}
        height="full"
        disableCustomCursor={false}
      >
        <div className="minimal-hero__block">
          <ImgLayeringLive scrollSpeed="-2" id="miniHeroLayering" />
          <HeroAnimationHeading />
          <HeroHeadingLinks />
        </div>
      </Section>
    </HeroContext.Provider>
  );
};

export default MinimalHero;

